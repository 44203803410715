/* Global css styles here - otherwise do not TOUCH!!! */

@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400&family=Fira+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400&family=Fira+Mono&family=Roboto+Mono:wght@300&display=swap");

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-font-color: #00102a;
  --secondary-font-color: palevioletred;
  --tertiary-font-color: rgb(87, 78, 78);
  --darker-primary: #ffe5bb;
  --primary-bg-color: papayawhip;
  --font-primary: "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-secondary: "Fira Code", "Fira Mono", "Roboto Mono", monospace;
  --bg-tint: rgba(219, 112, 147, 0.2);
  --small-text-size: 0.9rem;
}

img {
  display: block;
  width: 100%;
}

button {
  cursor: pointer;
  display: inline-block;
}

h1,
h2,
h3,
h4 {
  color: var(--tertiary-font-color);
  /* font-weight: bold; */
  font-weight: normal;
  font-size: 3rem;
  /* margin: 20px 0; */
}

p {
  font-size: 1.2rem;
  margin: 10px 0;
}

body {
  line-height: 1.2;
  background: var(--primary-bg-color);
  color: var(--primary-font-color);
  font-weight: normal;
  font-family: var(--font-primary);
  font-size: 10px;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}
